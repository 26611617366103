.table {
  padding: 20px 0;
  margin: 0 auto;
  tbody th {
    text-decoration: underline;
  }
  :global {
    .MuiPaper-root {
      box-shadow: none;
    }
    .Mui-selected {
      background-color: #fff !important;
      border-color: #68d0e7;
    }
  }
  &.clickable {
    tr {
      cursor: pointer;
      &:hover, &:active {
        th {
          text-decoration: none;
        }
      }
    }
  }
}
