.app {
  background: #eceeef;
  min-height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  .logo {
    max-width: 110px;
    transition: .3s linear;
    &:hover, &:active {
      opacity: .7;
    }
  }
}

@media screen and (max-width: 768px) {
  .app {
    padding: 0;
  }
}
