.simplePieChart {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;

  :global {
    .recharts-wrapper {
      margin : 0 auto;
    }
  }
}
