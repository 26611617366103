*, *:after, *:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  color: #374047;
  font-family: 'Nunito Sans', sans-serif;
}

button.MuiButtonBase-root,
th.MuiTableCell-root,
td.MuiTableCell-root {
  color: #374047;
  font-family: 'Nunito Sans', sans-serif;
}

th.MuiTableCell-root,
{
  font-weight: 600;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

img {
  width: 100%;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.custom-tooltip {
  padding: 12px;
  border-radius: 2px;
  background-color: black;

  p {
    color: white;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .blue {
    color: #68d0e7;
  }

  .light-blue {
    color: #0788a5;
  }
}


// select styles global
.MuiMenuItem-root svg {
  opacity: 0;
  display: inline-block;
  margin-right: 4px;
}

.Mui-selected svg {
  opacity: 1;
  display: inline-block;
}

.MuiSelect-select svg {
 display: none;
}

.MuiInputBase-root {
  height: 54px;
}
