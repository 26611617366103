.stackedBarChart {
  width: 100%;

  :global {
    .recharts-bar-rectangle {
      cursor: pointer;
    }
  }

  .legend {
    margin-top: 10px;
    margin-left: auto;
    width: calc(100% - 60px);
    display: flex;
    align-items: center;
    justify-content: space-around;

    img {
      max-width: 7%;
      max-height: 32px;
      object-fit: contain;
    }
  }
}

@media screen and (min-width: 992px) {
  .stackedBarChart {
    .title {
      br {
        display: none;
      }
    }
  }
}
